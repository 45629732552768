@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Lato:100,200,300,400,700,800,900&display=swap');

@layer base {          
    h1 {
        @apply
        text-[36px]
        font-extrabold
        capitalize
        ;        
    }
    h2 {
        @apply
        text-[32px]
        font-extrabold
        capitalize
        ;
    }
    h3 {
        @apply
        text-[20px]
        font-extrabold
        ;
    }
    h4 {
        @apply
        text-[16px]
        font-extrabold
        ;
    }
    h5 {
        @apply
        text-[16px]
        ;
    }
    a {
        @apply
        active:text-primary-300
        cursor-pointer
        disabled:text-primary-500
        duration-150
        font-light
        hover:decoration-dashed
        hover:text-primary-300
        hover:underline
        hover:underline-offset-2
        text-primary-300
        outline-none
        focus:outline-none
        ;
    }

    a.cta {
        @apply
        active:text-primary-100
        disabled:text-primary-400
        hover:text-primary-300
        text-primary-200
        outline-none
        focus:outline-none
        ;
    }

    .button, .button[type="button"], .button[type="submit"] {
        @apply
        active:bg-grey-300
        bg-grey-400
        border-0
        disabled:cursor-not-allowed
        disabled:opacity-50
        duration-150
        font-medium
        hover:bg-grey-500
        justify-center
        px-4
        py-2
        rounded
        text-sm
        outline-none
        focus:outline-none
        ;
    }

    .button-no-bg-color {
        @apply
        border-0
        disabled:cursor-not-allowed
        disabled:opacity-50
        duration-150
        font-medium
        justify-center
        px-4
        py-2
        rounded
        text-sm
        outline-none
        focus:outline-none
        ;
    }

    th,
    td {
        @apply
        pb-4
        pr-4
        font-light
        ;
    }

    thead {
        @apply
        uppercase
        text-left
        ;
    }

    label {
        @apply
        block
        font-light
        text-primary-400
        outline-none
        focus:outline-none
        ;
    }

    input, a, button {
      @apply outline-none focus:outline-none active:outline-none
    }

    [type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="file"], [type="week"], [multiple], textarea, select {
        @apply
        block
        border-0    
        placeholder:text-white
        border-secondary-200
        disabled:cursor-not-allowed
        disabled:opacity-50     
        focus:border-2
        focus:border-primary-100
        focus:ring-0
        font-light
        px-2
        py-2
        w-72
        outline-none
        focus:outline-none
        ;
    }

    [type="checkbox"] {
        @apply text-primary-200 rounded-sm focus:outline-none outline-none;
    }

    [type="radio"] {
        @apply text-primary-200 rounded-sm focus:outline-none outline-none;
    }

    select {
        @apply pl-1 focus:outline-none outline-none;
    }
}

/*
/* Sidebar
*/
@layer components {
    .top-acronym-container {
        @apply flex items-center justify-center self-center flex-shrink-0 px-4 rounded-full h-14 w-14 border-2
    }

    .top-acronym {
        @apply font-normal text-2xl
    }

    .menu-item {
        @apply
        border-b
        ;
    }

    .menu-item-active {
        @apply
        bg-primary-300  
        border-b        
        ;
    }
}

@layer components {
    button.cta, button.cta[type="button"], button.cta[type="submit"] {
        @apply
        active:bg-primary-100
        bg-primary-200
        disabled:bg-primary-400
        hover:bg-primary-300
        text-white
        outline-none
        focus:outline-none
        ;
    }

    button.icon-button {
        @apply
        px-2
        py-2
        rounded-lg
        outline-none
        focus:outline-none
    }
}

/* Calendar */
@layer components {
    .react-calendar {
        @apply
        w-96
        text-center
        ;
    }

    .react-calendar button {
        @apply
        m-1;
    }

    .react-calendar .react-calendar__month-view__weekdays {
        @apply invisible;
    }

    .react-calendar .react-calendar__tile--now {
        @apply bg-cta-200 text-white;
    }
}

/* Floating labels */
@layer components {
    input + label,
    select + label {
        @apply absolute top-2 left-2;
    }

    select:focus-within ~ label,
    select:not(:placeholder-shown) ~ label,
    input:focus-within ~ label,
    input:not(:placeholder-shown) ~ label {
        @apply transform scale-75 origin-bottom-left -translate-y-6 z-0 py-0;
    }
}

/* height adjustments */
@layer components {
    .height-max-content {
        height: max-content;
    }
}

/* Sidebar */
@layer components {
    .bg-sidebar {
        @apply bg-primary-100
    }

    .top-acronym-container {
        @apply border-primary-300
    }

    .top-acronym {
        @apply text-primary-300
    }

    .menu-item {
        @apply
        text-primary-300
        ;
    }

    .menu-item-active {
        @apply
        text-primary-100
        ;
    }
}

.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.Toastify__toast--error {    
    border-radius: 8px !important;
    background: #FAE1E2 !important;
    width: 382px;
}